import React from 'react'
import { Link } from 'gatsby'
import 'prism-themes/themes/prism-darcula.css'
import '../styles/code.css'

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    header = (
      <h1
        style={{
          ...scale(0.8),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          border: 'none',
        }}
      >
        <Link
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            color: 'inherit',
          }}
          to={rootPath}
        >
          {title}
        </Link>
      </h1>
    )

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(30),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
      </div>
    )
  }
}

export default Layout
