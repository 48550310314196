import Typography from 'typography'
import githubTheme from 'typography-theme-github'

githubTheme.overrideThemeStyles = ({ rhythm }) => ({
  body: {
    lineHeight: 2.0,
  },
  p: {
    margin: '18px 0',
  },
  'h1, h2, h3, h4, h5, h6': {
    color: '#515151',
  },
})

const typography = new Typography(githubTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
